import { graphql } from "gatsby";
import React from "react";

// Components
import { HeroImage, Services } from "@components";

const services = ({ data }) => {
    const {
        allStrapiService: { nodes: servicesX },
    } = data;

    const services = [
        {
            strapi_id: 1,
            name: "Allied Health",
            phone: "5995-1000",
            image: "../../images/pages/medical.jpeg",
            openingHours: [],
        },
        {
            strapi_id: 2,
            name: "Dental Service",
            phone: "5995-1000",
            image: "../../images/pages/dentists.jpeg",
            openingHours: [
                {
                    name: "Monday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 17, 0),
                },
                {
                    name: "Tuesday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 17, 0),
                },
                {
                    name: "Wednesday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 17, 0),
                },
                {
                    name: "Thursday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 17, 0),
                },
                {
                    name: "Friday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 17, 0),
                },
            ],
        },
        {
            strapi_id: 3,
            name: "Doctors",
            phone: "5995-1000",
            image: "../../images/pages/doctors.jpeg",
            openingHours: [
                {
                    name: "Monday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 19, 0),
                },
                {
                    name: "Tuesday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 19, 0),
                },
                {
                    name: "Wednesday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 19, 0),
                },
                {
                    name: "Thursday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 19, 0),
                },
                {
                    name: "Friday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 19, 0),
                },
                {
                    name: "Saturday",
                    opening: new Date(2023, 12, 12, 9, 0),
                    closing: new Date(2023, 12, 12, 19, 0),
                },
            ],
        },
        {
            strapi_id: 4,
            name: "Specialists",
            phone: "5995-1000",
            image: "../../images/pages/doctors.jpeg",
            openingHours: [],
        },
    ];

    return (
        <>
            <HeroImage title={"Our Services"} />
            {services && <Services services={services} servicePage={true} />}
        </>
    );
};

export const query = graphql`
    query {
        # Services
        allStrapiService {
            nodes {
                strapi_id
                name
                phone
                image {
                    url
                }
                openingHours {
                    name
                    opening
                    closing
                }
            }
        }
    }
`;

export default services;
